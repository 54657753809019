<style lang="less" scoped>
.list{
  padding-left: 20px;
  height: 0px;
  overflow: hidden;
}
.cartoon{
    transition: all .25s ease;
}
.son-list-son{
    height: 45px;
    line-height: 45px;
    color: #ffffff;
    position: relative;
}
/* .son-list-son:hover{
  color: #1069b4;
} */
/* .son-list-son-on,.son-list-son-dian{
  background-color: #1069b4;
  color: #ffffff;
} */
.router-link-exact-active{
  color: #409EFF;
}
/* .router-link-active{
  color: #409EFF;
} */
.router-link-exact-active::before{
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #0192FD;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    left: -10px;
}
.router-link-active{
  color: #409EFF;
}
.router-link-active::before{
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #0192FD;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    left: -10px;
}
.router-link-exact-active:hover{
  /* color: #0192FD; */
}
.nav-list-son-j{
    height: 20px;
    width: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -20px;
    margin: auto;
}
.nav-list-son-j-z{
    transform: rotate(90deg);
}
.nav-list-son-jj{
    height: 8px;
    width: 8px;
    border-top: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    position: absolute;
    top: 50%;
    margin-top: -5px;
    right: 8px;
    transform: rotate(45deg);
}
.nav-list-son-jj-on{
    /* border-top: 1px solid #0192FD;
    border-right: 1px solid #0192FD; */
}
@keyframes v-modal-in {
    0% {
        opacity: 0;
    }
    100% {}
}
@keyframes v-modal-out {
    0% {}
    100% {
        opacity: 0;
    }
}
.v-modal-enter {
  animation: v-modal-in .2s ease;
}

.v-modal-leave {
  animation: v-modal-out .2s ease forwards;
}
</style>

<template>
  <div class="list cartoon" :style="listHeight">
    <div v-for='(item,index) of list' :key='index'>
      <div v-if="item.children.length < 1">
          <router-link class="son-list-son cursor" :to="item.href||'/404'">{{item.name}}</router-link>
      </div>
      <div v-else>
        <div class="son-list-son cartoon cursor" :class="[item.flag?'son-list-son-on':'']" @click="sonClick(index)">
          <span>{{item.name}}</span>
          <div class="nav-list-son-j cartoon" :class="{'nav-list-son-j-z':item.flag}">
              <div class="nav-list-son-jj cartoon" :class="{'nav-list-son-jj-on':item.flag}"></div>
          </div>
        </div>
        <DirectoryTreeSon :flag="item.flag" :data="item.children" @DirectoryTreeSonFun="DirectoryTreeSonFun"></DirectoryTreeSon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DirectoryTreeSon',
  data () {
    return {
      sonNum: '',
      sonNumCopy: '',
      list: ''
    }
  },
  props: ['flag', 'data'],
  created () {
    this.initialData()
  },
  watch: {
    flag: function (n, o) {
      if (n) {
        this.sonNum = this.sonNumCopy
      } else {
        this.sonNum = 0
      }
    }
  },
  computed: {
    listHeight: function () {
      return `height: ${this.sonNum * 45}px`
    }
  },
  methods: {

    // 初始化数据
    initialData () {
      const arr = this.$props.data
      if (this.$props.flag) {
        this.sonNum = arr.length
      } else {
        this.sonNum = 0
      }
      this.sonNumCopy = arr.length
      arr.forEach((val, ind) => {
        if (!val.children) {
          val.children = []
        }
        // if (val.list) {
        //   val.list.forEach((vall, indd) => {
        //     vall.click = false
        //     vall.flag = false
        //   })
        // }
      })
      this.list = arr
    },

    // 点击事件
    sonClick (index) {
      if (this.list[index].children) {
        let bool = false
        if (this.list[index].flag) {
          this.sonNumCopy -= this.list[index].children.length
        } else {
          this.sonNumCopy += this.list[index].children.length
          bool = true
        }
        this.sonNum = this.sonNumCopy
        this.list[index].flag = !this.list[index].flag
        const obj = {
          switch: bool,
          num: this.list[index].children.length
        }
        this.$emit('FffffFun', obj)
      }
    },

    // 子组件传过来的数值
    DirectoryTreeSonFun (e) {
      if (e.switch) {
        this.sonNumCopy += e.num
      } else {
        this.sonNumCopy -= e.num
      }
      this.sonNum = this.sonNumCopy
    }
  }
}
</script>
