<template>
  <div class="me-pos platform-box">
    <div class="me-left" :style="`width: ${listWidth}px;`">
      <My-DirectoryTree :type="type" :fold="fold"></My-DirectoryTree>
      <div class="menu-control-icon cursor" @click="menuControl">
        <i class="el-icon-s-grid" style="font-size: 26px;"></i>
      </div>
    </div>
    <div class="me-right" :style="`left: ${listWidth}px;`">
      <My-AdminHeader :left="listWidth"></My-AdminHeader>
      <div class="me-right-content">
        <div class="me-box">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyAdminHeader from '@/components/header/AdminHeader'
import MyDirectoryTree from '@/components/directoryTree/DirectoryTree'

export default {
  name: 'MeCar',
  data () {
    return {
      type: 'back_manager',
      listWidth: 200,
      fold: false
    }
  },
  watch: {},
  components: {
    MyAdminHeader,
    MyDirectoryTree
  },
  methods: {
    // 目录树 点击事件
    DirectoryTreeFun (e) {
      console.log(e)
    },
    menuControl () {
      if (this.fold) {
        this.listWidth = 200
        this.fold = false
      } else {
        this.listWidth = 65
        this.fold = true
      }
    }
  },
  created () {}
}
</script>

<style lang="less" scoped>
.me-pos{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.me-left{
  transition: all 0.3s;
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #3B4353;
}
.me-right{
  transition: all .3s;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
.menu-control-icon{
  height: 26px;
  width: 26px;
  // background-color: #FFB20C;
  position: absolute;
  top: 12px;
  right: -26px;
}
.me-right-content{
  padding-top: 50px;
  min-width: 1200px;
}
// .el-menu-vertical-demo:not(.el-menu--collapse) {
//   width: 200px;
// }
// .el-menu{
//   border-right: solid 0px #e6e6e6;
// }
</style>
