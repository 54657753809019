<style lang="less" scoped>
 /* 设置滚动条的样式 */
::-webkit-scrollbar {
    width:6px;
    /* background-color: #eaeaea; */
}
/* 滚动槽 */
::-webkit-scrollbar-track {
    border-radius:6px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius:6px;
    background:#ccc9c9;
}
.nav-list {
  position: absolute;
  top: 65px;
  left: 0;
  bottom: 30px;
  right: -1px;
  padding-left: 25px;
  overflow-y: auto;
}
.nav-list-son {
  display: block;
  height: 45px;
  line-height: 45px;
  position: relative;
  margin-top: 5px;
  font-size: 14px;
  color: #ffffff;
}
.nav-list-son-on {
  // font-weight: bold;
}
.nav-list-son-j {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 17px;
  margin: auto;
}
.nav-list-son-j-z {
  transform: rotate(90deg);
}
.nav-list-son-jj {
  height: 10px;
  width: 10px;
  border-top: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 5px;
  transform: rotate(45deg);
}
.nav-list-son-jj-on{
  // border-top: 1px solid #1069b4;
  // border-right: 1px solid #1069b4;
}
.cartoon{
  transition: all .25s ease;
}
.router-link-active{
  // color: #409EFF;
  color: #319BD0;
}
.title{
  margin: 15px 0 0 30px;
  color: #319BD0;
  font-size: 25px;
  text-align: center;
  height: 45px;
  width: 140px;
  overflow: hidden;
}
.fold-title{
  margin: 15px 0 0 15px;
  width: 40px;
  height: 40px;
}
.title-img{
  display: block;
  height: 35px;
  width: 140px;
}
.fold-title-img{
  height: 30px;
  width: 123px;
}
</style>

<template>
    <div class="nav">
      <div class="title" :class="{'fold-title': fold}">
        <img src="../../assets/image/logo.png" alt="" class="title-img cursor" :class="{'fold-title-img': fold}" @click="toHome">
      </div>
      <div class="nav-list">
        <div
          v-for="(item,index) of menuList"
          :key="index"
          @mouseenter="navMouse = index"
          @mouseleave="navMouse = -1"
        >
          <div v-if="item.children.length > 0"
            class="nav-list-son cursor"
            @click="navClick(index)"
            :class="{'nav-list-son-on':item.flag}"
          >
            <span class="iconfont" v-html='item.icon'></span>
            <span v-if="!fold">{{item.name}}</span>
            <div v-if="!fold" class="nav-list-son-j cartoon" :class="{'nav-list-son-j-z':item.flag}">
              <div class="nav-list-son-jj cartoon" :class="{'nav-list-son-jj-on':item.flag}"></div>
            </div>
          </div>
          <!-- <router-link v-else class="nav-list-son cursor" :to="item.href||'/404'"> -->
          <router-link v-else class="nav-list-son cursor" :to="item.href">
            <span class="iconfont" v-html='item.icon'></span>
            <span v-if="!fold">{{item.name}}</span>
          </router-link>
          <DirectoryTreeSon v-if="!fold" :flag="item.flag" :data="item.children"></DirectoryTreeSon>
        </div>
      </div>
    </div>
</template>

<script>
import DirectoryTreeSon from '@/components/directoryTree/DirectoryTreeSon'
import { myMenuTree } from '@/api/http'
export default {
  name: 'DirectoryTree',
  data () {
    return {
      navMouse: -1,
      sonMouse: -1,
      navOn: -1,
      sonOn: -1,
      menuList: '',
      GotIt: false
    }
  },
  // type: 必填  区分是车主中心还是货主中心
  // fold: 必填  是否折叠
  props: ['type', 'fold'],
  components: {
    DirectoryTreeSon
  },
  watch: {},
  created () {
    this.getData()
    this.watch_path(this.menuList)
  },
  computed: {},
  methods: {
    // 回首页
    toHome () {
      this.$router.push({ path: '/' })
    },
    // 获取数据
    getData () {
      myMenuTree({ type: this.$props.type }).then(res => {
        if (res.code === '000') {
          const list = [...res.result]
          list.forEach((val, ind) => {
            // val.flag = true
            val.flag = false
            if (!val.children) {
              val.children = []
            }
          })
          this.menuList = list
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 监听刷新后的路径
    watch_path (arr, array) {
      const that = this
      const path = that.$route.path
      if (that.GotIt) {
        return
      }
      if (arr) {
        arr.forEach((val, ind) => {
          if (val.children === null) {
            val.children = []
          }
          if (val.href === path || (path.indexOf(val.href) !== -1 && val.href)) {
            that.GotIt = true
            if (array) {
              array.flag = true
            }
          }
          if (val.children.length > 0) {
            if (that.GotIt) {
              return
            }
            this.watch_path(val.children, val)
          }
        })
      } else {
        setTimeout(() => {
          that.watch_path(that.menuList)
        }, 500)
      }
    },

    // 父导航点击
    navClick (index) {
      this.menuList[index].flag = !this.menuList[index].flag
    }
  }
}
</script>
